.product {
    color: var(--tg-theme-text-color);
    display: flex;
    flex-direction: column;
    border: 1px solid var(--tg-theme-button-color);
    padding: 15px;
    width: 170px;
}

.img {
    width: 100%;
    height: auto;
    margin: 0 auto 12px;
    background: var(--tg-theme-button-color);
}

.title {
    color: var(--tg-theme-text-color);
    margin-bottom: 12px;
}

.description {
    font-size: 0.8em;
}

.add-btn {
    width: 100%;
}
